import { configureStore } from "@reduxjs/toolkit";
import securityReducer from "./security";

import globalReducer from "../reducers/globalActions";

const store = configureStore({
  reducer: {
    loggedUserReducer: securityReducer,
    globalReducer,
  },
});

export default store;
