import React from "react";
import { formattedPrice } from "../utils";

const CheckoutProductItem = ({ item }) => {
  return (
    <div className="checkout-information-list-item">
      <div className="checkout-information-list-item-image">
        <img src={item?.image} alt="Fuka" />
      </div>
      <div className="checkout-information-list-item-text">
        <h5>{item?.name}</h5>
        {item?.size && (
          <div className="checkout-information-list-item-size">
            Size: {item?.size}
          </div>
        )}
        <div className="checkout-information-list-item-price-grinding">
          {item?.grinding && (
            <div className="checkout-information-list-item-grinding">
              Grinding: {item?.grinding}
            </div>
          )}
          <div className="checkout-information-list-item-price">
            {item?.qty} x {formattedPrice(item?.price)} <small>RSD</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutProductItem;
