import React, { useEffect, useRef, useState } from "react";
import Hero from "../components/Hero";
import ContactFooter from "../components/ContactFooter";
import { useTranslation } from "react-i18next";
import { basicFormValidationHandler } from "../utils";
import { isEmpty, isUndefined } from "lodash";
import { toast } from "react-toastify";
import InputField from "../components/Reusable/InputField";
import { useDispatch } from "react-redux";
import { sendContactFormFunction } from "../actions/global";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const recaptchaRef = useRef();

  // const key = "6LeUftEoAAAAAGV2wD_uDEKaIrwHMZFw7sKDgkC9"; //localhost
  const key = "6Le7BJYqAAAAALM_373PjAu7CYNDr_-Gq9L85ia7"; //fuka.rs

  const heroData = {
    image: "hero-contact.png",
    title: t("contact_page.title"),
    subtitle: t("contact_page.subtitle"),
    link: "/catalog/coffee",
    linkName: t("contact_page.linkText"),
  };

  const initialState = {
    name: "",
    subject: "",
    email: "",
    message: "",
    token: "",
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event?.target || {};

    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setData(initialState);
    setErrors({});
  };

  const clientValidationHandler = () => {
    const { name, email, subject, message } = data;
    const errorsObject = basicFormValidationHandler(
      {
        name,
        email,
        subject,
        message,
      },
      t
    );

    // Dodaj validaciju za email
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errorsObject.email = t("contact_page.form.email_invalid"); // Koristi odgovarajući tekst
    }

    setErrors(errorsObject);

    if (!isEmpty(errorsObject)) {
      toast.error(t("other.required_form_submit"), { theme: "colored" });
    }

    return !isEmpty(errorsObject); // Ako ima grešaka, vraća `true`
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (clientValidationHandler()) {
      return;
    }

    dispatch(sendContactFormFunction(data, t, resetForm, resetCaptcha));
  };

  const resetCaptcha = async () => {
    if (recaptchaRef?.current) {
      recaptchaRef.current.reset();

      // Ponovo generiši token (za invisible captcha)
      const newToken = await recaptchaRef.current.executeAsync();
      setData((prevData) => ({ ...prevData, token: newToken }));
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    if (recaptchaRef?.current) {
      const token = await recaptchaRef?.current.executeAsync();
      setData((prevData) => ({ ...prevData, token: token }));
    }
  };

  return (
    <div className="contact-holder-content">
      <Hero data={heroData} />
      <div className="contact-content-main">
        <div className="contact-content-form-holder">
          <div className="contact-content-information-content">
            <div className="section-title">
              <h2>{t("contact_page.find_us.title")}</h2>

              <div className="contact-content-information-holder">
                <div className="contact-information-items">
                  <div className="contact-information-item">
                    <div className="contact-information-item-image">
                      <div className="contact-information-image-box">
                        <img
                          src="/images/pin.png"
                          className="img-fluid"
                          alt="Fuka"
                        />
                      </div>
                    </div>
                    <div className="contact-information-item-text">
                      <h4>{t("contact_page.find_us.address")}:</h4>
                      <p>1234 Heaven Stress, Beverly Hill, Melbourne, USA.</p>
                    </div>
                  </div>
                  <div className="contact-information-item">
                    <div className="contact-information-item-image">
                      <img
                        src="/images/mail.png"
                        className="img-fluid"
                        alt="Fuka"
                      />
                    </div>
                    <div className="contact-information-item-text">
                      <h4>{t("contact_page.find_us.email")}:</h4>
                      <p>info@fuka.rs</p>
                    </div>
                  </div>
                  <div className="contact-information-item">
                    <div className="contact-information-item-image">
                      <img
                        src="/images/telephone.png"
                        className="img-fluid"
                        alt="Fuka"
                      />
                    </div>
                    <div className="contact-information-item-text">
                      <h4>{t("contact_page.find_us.phone")}:</h4>
                      <p>(800) 123 456 789, (800) 987 654 321</p>
                    </div>
                  </div>
                </div>
                <div className="contact-information-description">
                  <p>{t("contact_page.find_us.description")}</p>
                </div>
                <div className="contact-information-social">
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/fuka.bg/"
                        target="_blank"
                      >
                        <img
                          src="/images/instagram-black.png"
                          className="img-fluid"
                          alt="Fuka"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-content-form-content">
            <div className="section-title">
              <h2>{t("contact_page.title")}</h2>

              <div className="contact-form-container">
                <form onSubmit={submitHandler}>
                  <ReCAPTCHA
                    sitekey={key}
                    size="invisible"
                    ref={recaptchaRef}
                  />
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-4 col-md-6">
                      <InputField
                        id="outlined-basic"
                        label={t("contact_page.form.name")}
                        className="w-100"
                        name="name"
                        value={data.name}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </div>
                    <div className="col-12 col-lg-6 mb-4 col-md-6">
                      <InputField
                        id="outlined-basic"
                        label={t("contact_page.form.email")}
                        className="w-100"
                        name="email"
                        type="email"
                        value={data.email}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </div>
                    <div className="col-12 mb-4">
                      <InputField
                        id="outlined-basic"
                        label={t("contact_page.form.subject")}
                        className="w-100"
                        name="subject"
                        value={data.subject}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <InputField
                        id="outlined-basic"
                        label={t("contact_page.form.message")}
                        className="w-100"
                        name="message"
                        multiline
                        rows={5}
                        value={data.message}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </div>
                  </div>
                  <div className="contact-content-form-button">
                    <button className="button-custom-black">
                      {t("contact_page.form.submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-content-map-holder">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11315.385726345394!2d20.4135391!3d44.8450607!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a653299a154fb%3A0xf11774e94d1d22ff!2sFUKA!5e0!3m2!1sen!2srs!4v1732468143275!5m2!1sen!2srs"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <ContactFooter />
    </div>
  );
};

export default Contact;
