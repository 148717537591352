import React, { useState } from "react";

const NumberInput = ({ max = 10, handleChangeQty }) => {
  const [value, setValue] = useState(1);

  const handleIncrease = (event) => {
    setValue((prevValue) => {
      const newValue = prevValue < max ? prevValue + 1 : prevValue;
      handleChangeQty(event, newValue); // Prosleđivanje nove vrednosti parent komponenti
      return newValue;
    });
  };

  const handleDecrease = (event) => {
    setValue((prevValue) => {
      const newValue = prevValue > 1 ? prevValue - 1 : prevValue;
      handleChangeQty(event, newValue); // Prosleđivanje nove vrednosti parent komponenti
      return newValue;
    });
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= max) {
      setValue(newValue);
    }
  };

  return (
    <div className="number-input">
      <button className="minus" onClick={handleDecrease}>
        -
      </button>
      <input
        type="text"
        className="number"
        value={value}
        onChange={handleChange}
        min="1"
        max={max}
        disabled
      />
      <button className="plus" onClick={handleIncrease}>
        +
      </button>
    </div>
  );
};

export default NumberInput;
