import React from "react";
import Hero from "../components/Hero";
import ContactFooter from "../components/ContactFooter";

const TermsConditions = () => {
  const heroData = {
    title: "Uslovi & Privatnost.",
  };
  return (
    <div className="terms-holder-content">
      <div className="terms-hero-holder">
        <Hero data={heroData} />
      </div>
      <div className="terms-content-main">
        <h2>Uslovi korišćenja</h2>
        <p>
          Sadržaj sajta <b>fuka.rs</b> je vlasništvo kompanije <b>Fuka DOO</b>{" "}
          (u daljem tekstu „Prodavac“ ili „Fuka Coffee“). Korišćenjem našeg
          sajta, korisnici prihvataju sve uslove korišćenja i politiku
          privatnosti, koji su detaljno opisani u nastavku. Kupovinom proizvoda
          na našem sajtu, korisnik potvrđuje da je upoznat sa i prihvatio ove
          uslove. Prodavac zadržava pravo da u bilo kojem trenutku, bez
          prethodnog obaveštenja, izmeni ove uslove. Sve promene postaju
          obavezujuće za sve korisnike.
        </p>
        <h2>Plaćanje</h2>
        <p>
          Na <b>fuka.rs</b>, korisnicima su dostupni sledeći načini plaćanja:
        </p>
        <ul>
          <li>
            <b>Online plaćanje putem platnih kartica</b>
          </li>
          <li>
            <b>Plaćanje na bankovni račun</b>
          </li>
        </ul>
        <p>
          Za više informacija o opcijama plaćanja, posetite stranicu{" "}
          <b>Dostava & Plaćanje</b>
        </p>
        <h2>Dostava</h2>
        <p>
          <b>Fuka Coffee</b> ima sklopljen ugovor sa kurirskom službom{" "}
          <b>DExpress</b> za dostavu u Srbiji, sa mogućnošću isporuke „danas za
          sutra“. Nakon što narudžbina bude potvrđena i plaćena, kurirska služba
          preuzima dostavu. Isporuka se vrši radnim danima od 08 do 17 časova, a
          ne vrši se vikendom i praznicima. Nakon dva pokušaja dostave,
          narudžbina se vraća pošiljaocu.
        </p>
        <p>
          Očekivano vreme dostave je do 5 radnih dana (obično 3 dana) od potvrde
          narudžbine. Roba će biti spremna za dostavu najkasnije 2 radna dana
          nakon prijema narudžbine. Dostava je besplatna za narudžbine čiji
          iznos premašuje <b>5.000 RSD</b>, dok za manje narudžbine cena dostave
          iznosi <b>400 RSD sa PDV-om</b> i biće automatski obračunata prilikom
          kupovine.
        </p>
        <p>
          Za više informacija o dostavi, posetite stranicu{" "}
          <b>Dostava & Plaćanje</b>.
        </p>
        <h2>Cene proizvoda</h2>
        <p>
          Cene proizvoda su izražene u dinarima (<b>RSD</b>) i uključuju PDV.
          Cene proizvoda mogu se promeniti pre nego što narudžbina bude
          potvrđena. Cena dostave nije uključena u cenu proizvoda i biće
          prikazana na stranici potvrde kupovine.
        </p>
        <h2>Izdavanje računa</h2>
        <p>
          Kao poreski obveznik u Srbiji, <b>Fuka Coffee</b> je registrovan za
          PDV i sve fiskalne račune šalje putem email-a u PDF formatu.
          Prihvatate da račune dobijate elektronski putem email-a. Ako ste
          pravno lice, izdavaćemo eFakturu ili fiskalni račun sa <b>PIB-om</b>,
          uz prethodnu napomenu na stranici za plaćanje.
        </p>
        <h2>Intelektualna svojina</h2>
        <p>
          Svi materijali na našem sajtu (dizajn, tekstovi, slike, logotipi,
          audio, video sadržaji i softver) zaštićeni su autorskim pravima i
          vlasništvo su kompanije <b>Fuka DOO</b>. Neovlašćeno korišćenje bilo
          kojeg dela sadržaja može predstavljati kršenje zakona o autorskim
          pravima, žigovima ili drugih zakona koji se odnose na intelektualnu
          svojinu.
        </p>
        <p>
          Informacije prikazane na sajtu su isključivo informativne prirode i ne
          smeju se koristiti u komercijalne svrhe ili distribuirati trećim
          stranama bez prethodnog odobrenja.
        </p>
        <h2>Politika privatnosti</h2>
        <p>
          Vaša privatnost nam je veoma važna. Ova politika privatnosti opisuje
          koje informacije prikupljamo, kako ih koristimo i štitimo. Politika je
          primenljiva na sve informacije koje podelite sa sajtom <b>fuka.rs</b>{" "}
          i ne odnosi se na informacije prikupljene van sajta.
        </p>
        <p>
          <b>Lične informacije koje prikupljamo uključuju:</b>
        </p>
        <ul>
          <li>
            <b>Email adresu</b>
          </li>
          <li>
            <b>Ime i prezime</b>
          </li>
          <li>
            <b>Naziv firme</b>
          </li>
          <li>
            <b>Broj telefona</b>
          </li>
          <li>
            <b>Adresu</b>
          </li>
        </ul>
        <p>
          Ove informacije koristimo kako bismo obradili narudžbine, komunicirali
          sa korisnicima, unapredili funkcionalnosti sajta i razvijali nove
          proizvode i usluge. Takođe, prikupljamo informacije o korišćenju
          sajta, kao što su IP adresa, vrsta pretraživača, datum i vreme poseta,
          i koje stranice su posetili korisnici. Ove informacije se koriste za
          poboljšanje usluga i praćenje trendova, a ne mogu se povezati sa
          pojedincima.
        </p>
        <p>
          Naša internet prodavnica koristi kolačiće (<b>cookies</b>) za
          obezbeđivanje punih funkcionalnosti sajta i optimalnog korisničkog
          iskustva. Korišćenjem sajta saglasni ste sa upotrebom kolačića.
        </p>
      </div>{" "}
      <ContactFooter />
    </div>
  );
};

export default TermsConditions;
