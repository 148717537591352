import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className="intro-holder-main">
            <div className="intro-holder-main-content">
              <div className="intro-holder-main-content-text">
                <div className="intro-holder-main-content-image">
                  <img src="images/fuka-logo.png" alt="logo" />
                </div>
                <div className="intro-holder-main-content-second-image">
                  <img src="images/hero-home.png" alt="logo" />
                </div>
                <div className="intro-holder-main-content-title">
                  <h1>Coming Soon...</h1>
                  <p>Our website is under construction. Stay tuned!</p>
                </div>
                <div className="intro-holder-main-instagram-holder">
                  <div className="intro-holder-main-instagram">
                    <Link
                      to={"https://www.instagram.com/fuka.bg/"}
                      target="_blank"
                    >
                      <img src="images/instagram-black.png" alt="logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Intro;
