import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import ContactFooter from "../components/ContactFooter";
import Gallery from "../components/Gallery";
import Products from "../components/ProductsSection";
import InfoImageTextSection from "../components/InfoImageTextSection";
import EquipmentSection from "../components/EquipmentSection";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchCatalogList } from "../actions/global";
import { useSelector } from "react-redux";

const Home = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [activeLanguage, setActiveLanguage] = useState(i18next.language);
  const { dataCatalogList } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchCatalogList(0, activeLanguage));
  }, [dispatch]);

  const heroData = {
    image: "hero-home.png",
    title: t("home.title"),
    subtitle: t("home.description"),
    link: "/contact",
    linkName: t("home.link"),
  };

  return (
    <div className="home-holder-content">
      <Hero data={heroData} />
      <Products
        dataCatalogList={dataCatalogList?.filter(
          (item) => item.id_category == 1
        )}
      />
      <InfoImageTextSection />
      <EquipmentSection
        dataCatalogList={dataCatalogList?.filter(
          (item) => item.id_category == 2
        )}
      />
      <Gallery />
      <ContactFooter />
    </div>
  );
};

export default Home;
