import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import ContactFooter from "../components/ContactFooter";
import ProductItem from "../components/ProductItem";
import { useDispatch } from "react-redux";
import { fetchCatalogList } from "../actions/global";
import { useSelector } from "react-redux";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

const CatalogCoffee = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeLanguage, setActiveLanguage] = useState(i18next.language);
  const { dataCatalogList } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(fetchCatalogList(1, activeLanguage));
  }, [dispatch]);

  const heroData = {
    image: "hero-coffee.png",
    title: t("pages.coffee_title"),
  };

  useEffect(() => {
    // Funkcija koja se pokreće kada se promeni jezik
    const handleLanguageChange = (lng) => {
      dispatch(fetchCatalogList(1, lng));
    };

    // Dodavanje event listener-a za promenu jezika
    i18next.on("languageChanged", handleLanguageChange);

    // Čišćenje event listener-a kada se komponenta unmount-uje
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, [dispatch]);

  return (
    <div className="catalog-holder-content">
      <Hero data={heroData} />
      <div className="products-catalog-filter-holder">
        <div className="showing-results">
          {t("other.showing_results")} {dataCatalogList.length}
        </div>
      </div>
      <div className="products-content-main">
        {dataCatalogList?.map((item, key) => (
          <ProductItem item={item} key={key} />
        ))}
      </div>
      <ContactFooter />
    </div>
  );
};

export default CatalogCoffee;
