import React, { useEffect, useRef, useState } from "react";

import CartModalItem from "../CartModalItem";
import ContactFooter from "../ContactFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { fetchGetItemsFromCart } from "../../actions/global";
import { formattedPrice } from "../../utils";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Navigation = () => {
  const [fixedMenu, setFixedMenu] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(i18next.language);
  const { t } = useTranslation();
  const cartRef = useRef(null);
  const dispatch = useDispatch();

  const { dataCartInfo, dataCartItemsInfo } = useSelector(
    (state) => state.globalReducer
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setFixedMenu(true);
      } else {
        setFixedMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setShowCart(false); // Zatvori korpu ako je klik bio van
      }
    };

    if (showCart) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showCart]);

  const changeLanguageFunction = (code) => {
    i18next.changeLanguage(code);
    setActiveLanguage(code);
  };

  if (showCart) {
    // Dodaj klasu na body kada je popup otvoren
    document.body.classList.add("no-scroll");
  } else {
    // Ukloni klasu kada je popup zatvoren
    document.body.classList.remove("no-scroll");
  }

  if (showMobileMenu) {
    // Dodaj klasu na body kada je popup otvoren
    document.body.classList.add("no-scroll");
  } else {
    // Ukloni klasu kada je popup zatvoren
    document.body.classList.remove("no-scroll");
  }

  const showCartItemsOpenCart = () => {
    if (dataCartInfo?.id && dataCartInfo?.total_order_items > 0) {
      setShowCart(true);
      dispatch(fetchGetItemsFromCart(dataCartInfo.id, activeLanguage));
    }
  };

  return (
    <>
      <header className={`header has-center ${fixedMenu && "fixed-menu"}`}>
        <div className="header-top">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="header-top-wrapper">
                  <div className="header-left-content">
                    <Link to={"/home"}>
                      <img
                        src="/images/fuka-logo.png"
                        alt="Fuka"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="header-center-content">
                    <ul>
                      <li>
                        <Link to="/coffee">{t("menu.coffee")}</Link>
                      </li>
                      <li>
                        <Link to="/equipment">{t("menu.equipment")}</Link>
                      </li>
                      <li>
                        <Link to="/about">{t("menu.about")}</Link>
                      </li>
                      <li>
                        <Link to="/contact">{t("menu.contact")}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="header-right-content">
                    <ul>
                      <li
                        onClick={(event) => {
                          event.nativeEvent.stopImmediatePropagation();
                          showCartItemsOpenCart();
                        }}
                      >
                        <div className="header-right-content-icon">
                          <img
                            src="/images/shopping-bag.png"
                            alt="Fuka"
                            className="img-fluid icon-header-image"
                          />
                        </div>
                        <div className="header-right-content-text">
                          {t("other.cart")} (
                          {dataCartInfo?.total_order_items
                            ? dataCartInfo?.total_order_items
                            : 0}
                          )
                        </div>
                      </li>
                      <li
                        className="langauge-secion-menu-bar-mobile d-none"
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                      >
                        <MenuIcon />
                      </li>
                      <li className="langauge-secion-list-li">
                        <div className="header-language-content">
                          <div
                            className={`header-lagunage-item ${
                              activeLanguage === "sr" ? "active" : ""
                            }`}
                            onClick={() => changeLanguageFunction("sr")}
                          >
                            SR
                          </div>{" "}
                          |
                          <div
                            className={`header-lagunage-item ${
                              activeLanguage === "en" ? "active" : ""
                            }`}
                            onClick={() => changeLanguageFunction("en")}
                          >
                            EN
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`cart-holder ${showCart ? "active" : ""}`}
          ref={cartRef}
        >
          <div className="cart-holder-content">
            <div className="cart-holder-header">
              <div className="cart-holder-header-title">
                {t("shopping_cart_modal.title")}
              </div>
              <div
                className="cart-holder-header-close"
                onClick={() => setShowCart(false)}
              >
                <FontAwesomeIcon icon={faClose} />
              </div>
            </div>
            <div className="cart-holder-body">
              <div className="cart-body-content">
                {dataCartItemsInfo?.length == 0 ? (
                  <div className="cart-content-empty">
                    <h4>{t("shopping_cart_modal.empty_cart")}</h4>
                  </div>
                ) : (
                  <div className="cart-content-items">
                    {dataCartItemsInfo?.map((item, key) => (
                      <CartModalItem item={item} key={key} />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="cart-holder-footer-total">
              <div className="cart-holder-footer-total-title">
                {t("shopping_cart_modal.total")}:
              </div>
              <div className="cart-holder-footer-total-price">
                {formattedPrice(dataCartInfo?.total_price_sum)}{" "}
                <small>RSD</small>
              </div>
            </div>
            <div className="cart-holder-footer">
              <Link to={"/cart"} className="w-100">
                <button
                  className="button-custom-black w-100"
                  onClick={() => setShowCart(false)}
                >
                  {t("shopping_cart_modal.view_cart")}
                </button>
              </Link>
              <Link to={"/checkout"} className="w-100">
                <button
                  className="button-custom-silver w-100"
                  onClick={() => setShowCart(false)}
                >
                  {t("shopping_cart_modal.checkout")}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className={`mobile-menu-holder ${showMobileMenu ? "active" : ""}`}>
          <div className="mobile-menu-holder-content">
            <div className="mobile-menu-header">
              <div className="mobile-menu-header-image">
                <img
                  src="/images/fuka-logo.png"
                  alt="Fuka"
                  className="img-fluid"
                />
              </div>
              <div className="mobile-menu-holder-language-mobile">
                <div className="header-language-content">
                  <div
                    className={`header-lagunage-item ${
                      activeLanguage === "sr" ? "active" : ""
                    }`}
                    onClick={() => changeLanguageFunction("sr")}
                  >
                    SR
                  </div>{" "}
                  |
                  <div
                    className={`header-lagunage-item ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguageFunction("en")}
                  >
                    EN
                  </div>
                </div>
              </div>
              <div className="mobile-menu-holder-content-close">
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => setShowMobileMenu(false)}
                />
              </div>
            </div>
            <div className="mobile-menu-holder-content-items">
              <ul onClick={() => setShowMobileMenu(false)}>
                <li>
                  <Link to="/coffee">{t("menu.coffee")}</Link>
                </li>
                <li>
                  <Link to="/equipment">{t("menu.equipment")}</Link>
                </li>
                <li>
                  <Link to="/about">{t("menu.about")}</Link>
                </li>
                <li>
                  <Link to="/contact">{t("menu.contact")}</Link>
                </li>
              </ul>
            </div>
            <div className="mobile-menu-holder-content-footer">
              <ContactFooter />
            </div>
          </div>
        </div>
      </header>

      {showCart && <div className="overlay-cart-background" />}
    </>
  );
};

export default Navigation;
