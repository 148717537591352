import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content-holder">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-content">
                <div className="footer-content-left">
                  <div className="footer-content-logo">
                    <img src="/images/fuka-logo-white.png" alt="Fuka" />
                    <div className="footer-content-logo-text">
                      ©2024 fuka.rs, Izrada{" "}
                      <a href="https://www.developers.rs" target="_blank">
                        Developers.rs.
                      </a>{" "}
                      Sva prava zadržana.
                    </div>
                  </div>
                </div>
                <div className="footer-content-right">
                  <div className="footer-content-right-icons">
                    <div className="footer-icon">
                      <img src="/images/facebook.png" alt="Facebook" />
                    </div>
                    <div className="footer-icon">
                      <img src="/images/instagram.png" alt="Instagram" />
                    </div>
                    <div className="footer-icon">
                      <img src="/images/twitter.png" alt="Twitter" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
