import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import { fetchRemoveItemFromCart } from "../actions/global";
import { formattedPrice } from "../utils";
import i18next from "i18next";

const CartModalItem = ({ item }) => {
  const dispatch = useDispatch();

  const removeItemFromCart = (item) => {
    dispatch(fetchRemoveItemFromCart(item.id_order, item.id, i18next.language));
  };

  return (
    <div className="cart-content-item">
      <div className="cart-content-item-image">
        <img src={item?.image} alt="Fuka" className="img-fluid" />
      </div>
      <div className="cart-content-item-text">
        <h5>{item?.name}</h5>
        <div className="cart-content-item-price">
          {item?.qty} x <span>{formattedPrice(item?.price)}</span>{" "}
          <small>RSD</small>
        </div>
      </div>
      <div
        className="cart-content-item-remove"
        onClick={() => removeItemFromCart(item)}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
    </div>
  );
};

export default CartModalItem;
