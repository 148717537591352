import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PaymentOptions = ({ handleInputChangePaymentMethod, t }) => {
  const [selectedPayment, setSelectedPayment] = useState(1);

  const handleChange = (value) => {
    setSelectedPayment(value);
    handleInputChangePaymentMethod(value);
  };

  return (
    <div>
      <Accordion
        expanded={selectedPayment === 1}
        onChange={() => handleChange(1)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="payment-methods-title"
        >
          <FormControlLabel
            className="payment-methods-title-h5"
            value="1"
            control={
              <Radio
                checked={selectedPayment === 1}
                onChange={() => handleChange(1)}
              />
            }
            label={t("payment_page.form.payment_method.method_1.title")}
          />
        </AccordionSummary>
        <AccordionDetails className="payment-methods-accordion-detail">
          <Typography>
            <div className="payment-methods-description">
              {t("payment_page.form.payment_method.method_1.desc")}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={selectedPayment === 2}
        onChange={() => handleChange(2)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="payment-methods-title"
        >
          <FormControlLabel
            className="payment-methods-title-h5"
            value="2"
            control={
              <Radio
                checked={selectedPayment === 2}
                onChange={() => handleChange(2)}
              />
            }
            label={t("payment_page.form.payment_method.method_2.title")}
          />
        </AccordionSummary>
        <AccordionDetails className="payment-methods-accordion-detail">
          <Typography>
            <div className="payment-methods-description">
              {t("payment_page.form.payment_method.method_2.desc")}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PaymentOptions;
