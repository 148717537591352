import React from "react";

const AboutInfoSection = ({ t }) => {
  return (
    <div className="about-info-section-holder">
      <div className="about-info-section-content">
        <div className="section-title">
          <h2>{t("about.section_3.title")}</h2>
        </div>
        <div className="about-info-section-items">
          <div className="about-info-section-item">
            <div className="about-info-section-item-image">
              <img src="/images/icon_01.png" className="img-fluid" alt="Fuka" />
            </div>
            <div className="about-info-section-item-text">
              <h4>{t("about.section_3.item_1.title")}</h4>
              <p>{t("about.section_3.item_1.desc")}</p>
            </div>
          </div>
          <div className="about-info-section-item">
            <div className="about-info-section-item-image">
              <img src="/images/icon_02.png" className="img-fluid" alt="Fuka" />
            </div>
            <div className="about-info-section-item-text">
              <h4>{t("about.section_3.item_2.title")}</h4>
              <p>{t("about.section_3.item_2.desc")}</p>
            </div>
          </div>
          <div className="about-info-section-item">
            <div className="about-info-section-item-image">
              <img src="/images/icon_03.png" className="img-fluid" alt="Fuka" />
            </div>
            <div className="about-info-section-item-text">
              <h4>{t("about.section_3.item_3.title")}</h4>
              <p>{t("about.section_3.item_3.desc")}</p>
            </div>
          </div>
          <div className="about-info-section-item">
            <div className="about-info-section-item-image">
              <img src="/images/icon_04.png" className="img-fluid" alt="Fuka" />
            </div>
            <div className="about-info-section-item-text">
              <h4>{t("about.section_3.item_4.title")}</h4>
              <p>{t("about.section_3.item_4.desc")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInfoSection;
