import ProductItem from "./ProductItem";
import React from "react";

const RelatedProducts = ({ items }) => {
  return (
    <div className="related-products-section-holder">
      <div className="section-title">
        <h2>Related Products.</h2>
      </div>
      <div className="related-products-section-content">
        <div className="products-content-main">
          {items?.map((item, key) => (
            <ProductItem item={item} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedProducts;
