import "./App.css";

import { Route, Routes } from "react-router-dom";
import AuthContext from "./context/auth_context";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import store from "./reducers";
import { securityActions } from "./reducers/security";
import { globalActions } from "./reducers/globalActions";

//css
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import "./assets/global.scss";
import "./assets/responsive.scss";

//pages
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import SuccessPage from "./pages/SuccessPage";
import CatalogCoffee from "./pages/CatalogCoffee";
import CatalogEquipment from "./pages/CatalogEquipment";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Checkout from "./pages/Checkout";
import Cart from "./pages/Cart";
import Search from "./pages/Search";
import ProductDetail from "./pages/ProductDetail";
import SecuredRoute from "./utils/security/SecuredRoute";
import TermsConditions from "./pages/TermsConditions";
import Intro from "./pages/Intro";

const phpToken = localStorage.getItem("phpToken");
const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

if (phpToken) {
  let items = {
    validToken: phpToken,
    loggedUser,
  };
  store.dispatch(securityActions.setCurrentUser(items));
}

const cartData = localStorage.getItem("cart");

if (cartData && cartData !== "undefined") {
  const infoCart = JSON.parse(cartData);
  store.dispatch(globalActions.dataCartInfo(infoCart));
} else {
  // Opcionalno, obavestite korisnika da je korpa prazna
  localStorage.removeItem("cart");
}

function App() {
  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  return (
    <AuthContext.Provider
      value={{
        ...loggedUserReducer,
      }}
    >
      <Routes>
        <Route exact path="/" element={<Intro />} />
        <Route
          exact
          path="/home"
          element={
            <SecuredRoute>
              <Home />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/equipment"
          element={
            <SecuredRoute>
              <CatalogEquipment />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/coffee"
          element={
            <SecuredRoute>
              <CatalogCoffee />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/product/:id"
          element={
            <SecuredRoute>
              <ProductDetail />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <SecuredRoute>
              <Contact />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <SecuredRoute>
              <About />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/checkout"
          element={
            <SecuredRoute>
              <Checkout />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/terms-and-conditions"
          element={
            <SecuredRoute>
              <TermsConditions />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/cart"
          element={
            <SecuredRoute>
              <Cart />
            </SecuredRoute>
          }
        />
        <Route
          exact
          path="/search"
          element={
            <SecuredRoute>
              <Search />
            </SecuredRoute>
          }
        />

        <Route
          path="/success-page"
          element={
            <SecuredRoute>
              <SuccessPage />
            </SecuredRoute>
          }
        />

        <Route
          path="*"
          element={
            <SecuredRoute>
              <ErrorPage />
            </SecuredRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </AuthContext.Provider>
  );
}

export default App;
