import React from "react";
import ProductItem from "./ProductItem";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Products = ({ dataCatalogList }) => {
  const { t } = useTranslation();
  return (
    <div className="products-section-holder">
      <div className="products-content-main">
        {dataCatalogList?.map((item, key) => (
          <ProductItem item={item} key={key} />
        ))}
      </div>
      <div className="product-button-holder">
        <Link to={`/coffee`}>
          <button className="button-custom-black">
            {t("other.button_see_all_products")}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Products;
